import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/layout";
import { TradeInPage } from "../modules/tradein";
import "../styles/1.reset.scss";
import "../styles/2.main.scss";

export default () => {
  return (
    <div>
      <Helmet>
        <title>iPhone в зачет</title>
      </Helmet>
      <Layout>
        <TradeInPage />
      </Layout>
    </div>
  );
};
