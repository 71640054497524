import { useStaticQuery, graphql } from "gatsby";

export const useTradeInPageData = () => {
  const { prismicTradeinpage } = useStaticQuery(
    graphql`
      query {
        prismicTradeinpage {
          data {
            configuration_options {
              price
              option {
                text
              }
            }
            configuration_options_title {
              text
            }
            description {
              text
            }
            info {
              text
            }
            memory_options_title {
              text
            }
            phone_models {
              price_16_gb
              price_32_gb
              price_64_gb
              price_128_gb
              price_256_gb
              price_512_gb
              model {
                text
              }
            }
            phone_title {
              text
            }
            preliminary_cost_block_image {
              url
            }
            preliminary_cost_block_title {
              text
            }
            state_options {
              factor
              option {
                text
              }
            }
            state_options_title {
              text
            }
            title {
              text
            }
          }
        }
      }
    `
  );
  return prismicTradeinpage.data;
};
