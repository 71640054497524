import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTradeInPageData } from "../../hooks/useTradeInPageData";
import styled from "styled-components";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import find from "lodash/find";
import { PreliminaryPrice } from "../../components/preliminaryprice/preliminaryprice";
import { Select } from "../../components/select/select";
import Option from "../../components/option";
import { Modal } from "../../components/modal";
import { RequestCallModal } from "../homepage/RequestCallModal";

interface Option {
  price: number;
  option: {
    text: string;
  };
}

interface PhoneModel {
  model: {
    text: string;
  };
}

const setInitialConfiguration = (options: string[]) => {
  return fromPairs(map(options, item => [item, false]));
};

const StylesTradeInPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 80px 32px;

  @media (max-width: 991.98px) {
    flex-direction: column;
    padding: 40px 80px 64px;
  }

  @media (max-width: 767.98px) {
    padding: 28px 16px;
    flex-direction: column-reverse;
  }

  .trade-in-page__content {
    max-width: 556px;
    width: 100%;
    margin-left: 80px;

    @media (max-width: 991.98px) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  .trade-in-page__title-wrap {
    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .trade-in-page__title-wrap--tablet {
    @media (min-width: 992px) {
      display: none;
    }

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  .trade-in-page__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  }

  .trade-in-page__description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
    color: #5b5b5b;
  }

  .trade-in-page__block {
    margin-top: 64px;

    @media (max-width: 991.98px) {
      margin-top: 48px;
    }

    @media (max-width: 767.98px) {
      margin-top: 0;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 32px;
      color: #000000;

      @media (max-width: 991.98px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767.98px) {
        display: none;
      }

      &.memory-title,
      &.configuration-title,
      &.state-title {
        margin-bottom: 12px;

        @media (max-width: 991.98px) {
          margin-bottom: 0;
        }
      }
    }

    &-options {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 767.98px) {
        display: none;
      }

      &.state-options {
        justify-content: space-between;
      }
    }

    &-option {
      &.memory-option,
      &.configuration-option {
        margin: 20px 20px 0 0;
        min-width: 124px;
      }

      &.state-option {
        height: 80px;
        margin-top: 20px;
        width: calc((100% - 20px) / 2);
      }
    }

    &-info {
      font-size: 16px;
      line-height: 24px;
      color: #808080;

      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }

  .trade-in-page__problems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: 12px;

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  .trade-in-page__select {
    margin-top: 12px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .trade-in-page__problems--mobile {
    margin-top: 12px;

    @media (min-width: 768px) {
      display: none;
    }
  }
`;

const TradeInPage = () => {
  const {
    description,
    info,
    phone_models,
    phone_title,
    preliminary_cost_block_image,
    preliminary_cost_block_title,
    configuration_options,
    configuration_options_title,
    memory_options_title,
    state_options,
    state_options_title,
    title
  } = useTradeInPageData();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
  const handleCloseModal = useCallback(() => setModalIsOpen(false), []);

  const configurationOptions = configuration_options.map((stateOption: Option) => stateOption.option.text);
  const stateOptions = state_options.map((stateOption: Option) => stateOption.option.text);

  const initialPhoneModel = phone_models[0].model.text;
  const initialPhoneState = stateOptions[0];

  const [phoneModel, setPhoneModel] = useState(initialPhoneModel);
  const [phoneState, setPhoneState] = useState(initialPhoneState);
  const [configuration, setConfiguration] = useState(() => setInitialConfiguration(configurationOptions));

  const handleSetPhoneModel = useCallback((value: string) => setPhoneModel(value), []);
  const handleSetPhoneState = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => setPhoneState(event.currentTarget.value),
    []
  );
  const handleSelectPhoneState = useCallback((value: string) => setPhoneState(value), []);

  const handleSelectPhoneConfiguration = useCallback(
    (value: string) =>
      setConfiguration(prevState => ({
        ...prevState,
        [value]: !prevState[value]
      })),
    []
  );

  const handleSetPhoneConfiguration = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    event.persist();
    const value = event.currentTarget.value;
    handleSelectPhoneConfiguration(value);
  }, []);

  const usedConfigurations = keys(pickBy(configuration));
  const configurationSum = configuration_options.reduce((sum: number, current: Option) => {
    if (usedConfigurations.includes(current.option.text)) {
      return sum + current.price;
    }
    return sum;
  }, 0);

  const { factor } = find(state_options, ["option.text", phoneState]);

  const { price_16_gb, price_32_gb, price_64_gb, price_128_gb, price_256_gb, price_512_gb } = find(phone_models, [
    "model.text",
    phoneModel
  ]);

  const memoryOptionsWithPrices: { [key: string]: number } = useMemo(
    () => ({
      "16 GB": price_16_gb,
      "32 GB": price_32_gb,
      "64 GB": price_64_gb,
      "128 GB": price_128_gb,
      "256 GB": price_256_gb,
      "512 GB": price_512_gb
    }),
    [price_16_gb, price_32_gb, price_64_gb, price_128_gb, price_256_gb, price_512_gb]
  );

  const memoryOptions = useMemo(() => keys(pickBy(memoryOptionsWithPrices)), [memoryOptionsWithPrices]);

  const [phoneMemory, setPhoneMemory] = useState(memoryOptions[memoryOptions.length - 1]);

  const handleSetPhoneMemory = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => setPhoneMemory(event.currentTarget.value),
    []
  );
  const handleSelectPhoneMemory = useCallback((value: string) => setPhoneMemory(value), []);

  useEffect(() => {
    setPhoneMemory(memoryOptions[memoryOptions.length - 1]);
  }, [memoryOptions]);

  const price = memoryOptionsWithPrices[phoneMemory] * factor + configurationSum;

  return (
    <StylesTradeInPage>
      <div className="trade-in-page__title-wrap--tablet">
        <h3 className="trade-in-page__title">{title.text}</h3>
        <p className="trade-in-page__description">{description.text}</p>
      </div>
      <PreliminaryPrice
        image={preliminary_cost_block_image.url}
        title={preliminary_cost_block_title.text}
        content={price}
        onRequestCall={handleOpenModal}
      />
      <div className="trade-in-page__content">
        <div className="trade-in-page__title-wrap">
          <h3 className="trade-in-page__title">{title.text}</h3>
          <p className="trade-in-page__description">{description.text}</p>
        </div>

        <div className="trade-in-page__block">
          <h4 className="trade-in-page__block-title">{phone_title.text}</h4>
          <Select value={phoneModel} onChange={handleSetPhoneModel}>
            {phone_models.map(({ model }: PhoneModel) => (
              <option value={model.text} key={model.text}>
                {model.text}
              </option>
            ))}
          </Select>
        </div>
        <div className="trade-in-page__block">
          <div className="trade-in-page__block-title memory-title">{memory_options_title.text}</div>
          <div className="trade-in-page__block-options">
            {memoryOptions.map((option: string) => (
              <Option
                label={option}
                key={option}
                value={option}
                checked={phoneMemory === option}
                type="radio"
                className="trade-in-page__block-option memory-option"
                onChange={handleSetPhoneMemory}
              />
            ))}
          </div>
        </div>
        <Select
          placeholder="Обьем памяти"
          value={phoneMemory}
          onChange={handleSelectPhoneMemory}
          className="trade-in-page__select"
        >
          {memoryOptions.map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <div className="trade-in-page__block">
          <div className="trade-in-page__block-title state-title">{state_options_title.text}</div>
          <div className="trade-in-page__block-options state-options">
            {stateOptions.map((option: string) => (
              <Option
                label={option}
                key={option}
                value={option}
                checked={phoneState === option}
                type="radio"
                className="trade-in-page__block-option state-option"
                onChange={handleSetPhoneState}
              />
            ))}
          </div>
        </div>
        <Select
          placeholder="Состояние дисплея и корпуса"
          value={phoneState}
          onChange={handleSelectPhoneState}
          className="trade-in-page__select"
        >
          {stateOptions.map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <div className="trade-in-page__block">
          <div className="trade-in-page__block-title configuration-title">{configuration_options_title.text}</div>
          <div className="trade-in-page__block-options configuration-options">
            {configurationOptions.map((option: string) => (
              <Option
                label={option}
                key={option}
                value={option}
                checked={configuration[option]}
                type="checkbox"
                className="trade-in-page__block-option configuration-option"
                onChange={handleSetPhoneConfiguration}
              />
            ))}
          </div>
        </div>
        <Select
          placeholder="Комплектация"
          multiple
          value={configuration}
          onChange={handleSelectPhoneConfiguration}
          className="trade-in-page__select"
        >
          {configurationOptions.map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <div className="trade-in-page__block">
          <span className="trade-in-page__block-info">{info.text}</span>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <RequestCallModal />
      </Modal>
    </StylesTradeInPage>
  );
};

export { TradeInPage };
